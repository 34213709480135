import React, { Component } from "react";
import _ from "lodash";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Img from "gatsby-image";
import SEO from "../components/seo";
const { htmlToText } = require("html-to-text");
const dictionary = require("../dic/dic.json");

class BuscadorTemplate extends Component {
  constructor (props) {
    super(props);
    // console.log(props);
    this.state = { posts_mostrar: [], post_found: 0, search: "" };
  }

  componentDidMount () {
    function getParameterByName (name) {
      name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
      const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
      const results = regex.exec(location.search);
      return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }
    const search = getParameterByName("search");
    //let words=search.split(" ");
    //console.log(search);

    const posts = this.props.data.allWpPost.nodes;
    const posts_mostrar = [];
    let post_found = 1;
    // loop for known what posts we will show
    if (search !== "") {
      for (const post in posts) {
        //for (const word in words) {
          //if (words[word].length > 2) {
            if (posts[post].title.toLowerCase().includes(search.toLowerCase()) || (posts[post].content !== null ? posts[post].content.toLowerCase().includes(search.toLowerCase()) : null) || posts[post].excerpt.toLowerCase().includes(search.toLowerCase())) {
              posts_mostrar.push(posts[post]);
              post_found = 2;
            }
          //}
        //}
      }
    }
    // console.log(posts_mostrar);
    this.setState({ posts_mostrar, post_found, search });
  }

  createSchema (seo, page, lang) {
    const appJsonAux = [];
    if (page.aplication_json_ld_reviews_gql.aplicationJsonLdReviews != null) {
      appJsonAux.push(page.aplication_json_ld_reviews_gql.aplicationJsonLdReviews);
    }
    // if(appJsonAux==undefined || appJsonAux==null){
    if (typeof window !== "undefined") {
      const elementsModul = document.querySelectorAll(".slide-entry-wrap .slide-entry");
      const itemListElement = [];
      // console.log("elementsModul", elementsModul)
      let position = 1;
      for (const elementModul of elementsModul) {
        const listItem = {
          "@type": "ImageObject",
          contentUrl: elementModul.querySelector("picture img").src,
          name: elementModul.getElementsByClassName("caso-titulo")[0].innerHTML,
          description: elementModul.getElementsByClassName("caso-subtitulo")[0].innerHTML,
          position
        };
        const link = elementModul.getElementsByClassName("boton-secundario");

        if (link.length > 0) {
          listItem.url = link[0].href;
        }
        itemListElement.push(listItem);
        position++;
      }

      if (itemListElement.length > 0) {
        // console.log("app_json", this.state.app_json);
        const itemListElementString = JSON.stringify({
          "@context": "https://schema.org",
          "@type": "ItemList",
          itemListElement
        });
        appJsonAux.push(itemListElementString);
      }
    }

    const pageSchema = {
      "@context": "https://schema.org",
      "@type": "WebPage",
      name: seo.title,
      description: seo.metaDesc,
      datePublished: page.date,
      dateModified: page.modified
    };
    if (page.author !== null && page.author.node.avatar !== undefined) {
      let authorSchema = {
        "@type": "Person",
        name: page.author.node.name,
        image: page.author.node.avatar.url,
        url: "/" + lang + "/" + page.author.node.slug
      };
      if (page.author.node.name === "billage") {
        authorSchema = {
          "@type": "Organization",
          name: page.author.node.name,
          logo: "/images/logo-billage-color.svg"
        };
      }
      pageSchema.author = authorSchema;
    }
    const pageSchemaString = JSON.stringify(pageSchema);

    appJsonAux.push(pageSchemaString);
    // console.log("JSON", appJsonAux);
    return appJsonAux;
  }

  render () {
    const langPage = this.props.pageContext.idioma;
    const page = this.props.data.wpPage;
    const alternates = page.translated;
    const developers = this.props.data.wpPage.developers;
    const seo = this.props.data.wpPage.seo;
    const idPage = page.databaseId;
    const app_json = this.createSchema(seo, page, langPage);
    const slugPage = seo.canonical;
    const openGraphslugPage = seo.opengraphUrl;
    var contador_posts = 0;
    const main_cut_slug = "blog";
    var nameCat;
    var cat;
    return <Layout
        currentPage={page.title}
        langPage={langPage}
        idPage={this.props.data.wpPage.id}
        id_pagina={developers !== null ? developers.idPagina : ""}
        clase_pagina={developers !== null ? developers.clasePagina : ""}
        alternates={alternates}
        >
        <SEO
          title={seo.title}
          description={seo.metaDesc}
          canonical={slugPage}
          robotsNofollow="follow"
          robotsNoindex="noindex"
          openGraphSite={seo.opengraphSiteName}
          openGraphType={seo.opengraphType}
          openGraphUrl={openGraphslugPage}
          openGraphImg={
            seo.opengraphImage !== null
              ? seo.opengraphImage.sourceUrl
              : "/images/logo-billage-default-social-share.jpg"
          }
          openGraphImgWidth={
            seo.opengraphImage !== null
              ? seo.opengraphImage.mediaDetails.width
              : 300
          }
          openGraphImgHeight={
            seo.opengraphImage !== null
              ? seo.opengraphImage.mediaDetails.height
              : 300
          }
          dctermsCreated={this.props.data.wpPage.date}
          dctermsModified={this.props.data.wpPage.modified}
          lang={langPage}
          twitterImg={
            seo.twitterImage !== null
              ? seo.twitterImage.sourceUrl
              : "/images/logo-billage-default-social-share.jpg"
          }
          twitterImgHeight={
            seo.twitterImage !== null
              ? seo.twitterImage.mediaDetails.height
              : 300
          }
          twitterImgWidth={
            seo.twitterImage !== null
              ? seo.twitterImage.mediaDetails.width
              : 300
          }
          alternates={alternates}
          appJson={app_json}
          idPage={idPage}
        />
        <div className="wrapper">
        <header className="header-blog-home">
        <div className="titulares">
          <h1 className="text-center titol-buscador">{dictionary[langPage].resultado_busqueda}</h1>
        </div>
        <form method="GET" id="buscador-new">
                  <label htmlFor="search">{dictionary[langPage].buscador} </label>
                  <input type="name" id="buscador" name="search" defaultValue={this.state.search}></input>
                  <button type="submit" className="searchbutton" ><i className="fa fa-search" aria-hidden="true"></i></button>
                </form>
                </header>
        {this.state.post_found === 2 ?

        <div className="row post-list">
            {this.state.posts_mostrar.map(post => {
              // var main_cat = null // not used
              var category_count = 0;
              // loop for categorias
              {
                post.categories.nodes.map(categoria => {
                  category_count++;
                });
              }
              //if (contador_posts < 9 && category_count > 1) {
                contador_posts++;
                var mainImage = null;
                if (post.featuredImage !== null &&
                  post.featuredImage.node !== null &&
                  post.featuredImage.node.localFile !== null) {
                  mainImage = post.featuredImage.node.localFile.childImageSharp.fluid;
                  mainImage.sizes = "(max-width: 540px) 100px,(min-width: 768px) and (max-width: 1200px) 400px, 600px";
                }

                var html = post.excerpt;
                html = html.replace("[", "")
                html = html.replace("]", "")
                var post_excerpt = htmlToText(html, {
                  ignoreHref: true,
                })
                var post_excerpt = _.truncate(post_excerpt, {
                  length: 170,
                  separator: /,?\.* +/
                })

                return (
                  <div key={post.databaseId} className="post-wrapper col-12 col-md-6 col-lg-4">
                    <article className="post-item">
                      {post.categories.nodes.map(categoria => {
                        if (categoria.ancestors !== null && categoria.databaseId !== 9525 && categoria.databaseId !== 9552 && categoria.databaseId !== 9528 && categoria.databaseId !== 9531 && categoria.databaseId !== 9534 && categoria.databaseId !== 9537 && categoria.databaseId !== 9549 && categoria.databaseId !== 9540 && categoria.databaseId !== 9543 && categoria.databaseId !== 9546) {
                          cat = categoria.slug;
                          nameCat = categoria.name;
                        } else {
                          // if(categoria.databaseId==102 || categoria.databaseId==99){
                          //   main_cat = categoria.slug // not used
                          // }
                        }
                        return true;
                      })}
                      <div className="header">
                        {/* <Link to={`/${langPage}/${main_cut_slug}/${post.slug}`} className="overlay">
                          <span>Leer Más</span>
                        </Link> */}
                        <button className="overlay" onClick={() => { location.href = `/${langPage}/${main_cut_slug}/${post.slug}`; }}>
                            <span>Leer Más</span>
                        </button>

                        {mainImage !== null
                          ? (
                          <Img itemProp="image"
                            fluid={mainImage}
                            className="lazy header"
                            alt={post.featuredImage.node.altText}
                          />
                            )
                          : null}
                      </div>
                      <div className="inner">
                        <aside className="infopost">
                          <div className="author gravatar">
                            <img
                              src={post.author.node.avatar.url}
                              alt={post.author.node.name}
                              title={post.author.node.name}
                            />
                          </div>
                          <div className="metadata">
                            {nameCat}
                            <span>|</span>
                            {post.date}
                          </div>
                        </aside>
                        <h3 className="title"><Link to={`/${langPage}/${main_cut_slug}/${post.slug}`}>{post.title}</Link></h3>
                        <Link to={`/${langPage}/${main_cut_slug}/${post.slug}`}><p className="excerpt">{post_excerpt}</p></Link>
                      </div>
                    </article>
                  </div>
              );
              //}
            })}
          </div>
          :
          this.state.post_found === 1 ?
        <div className="not-found">
          <h2 className="text-center">{dictionary[langPage].no_post_found}</h2>
        </div>
        :
        <h2 className="text-center">{dictionary[langPage].buscando}</h2>
      }
      </div>
        </Layout>;
  }
}
export default BuscadorTemplate;
export const BuscadorQuery = graphql`
    query currentBuscador($id: String!, $databaseId: Int!) {
        wpPage(id: { eq: $id }) {
            title
            content
            slug
            id
            databaseId
            locale {
                id
            }
            page_content {
                contentRaw
            }
            developers {
                idPagina
                clasePagina
            }
            aplication_json_ld_reviews_gql {
                aplicationJsonLdReviews
            }
            date
            uri
            modified
            seo {
                canonical
                title
                metaDesc
                metaRobotsNofollow
                metaRobotsNoindex
                opengraphAuthor
                opengraphDescription
                opengraphSiteName
                opengraphTitle
                opengraphType
                opengraphUrl
                opengraphPublisher
                opengraphImage {
                    altText
                    sourceUrl
                    title
                    mediaDetails {
                        height
                        width
                    }
                }
                twitterDescription
                twitterTitle
                schema {
                    pageType
                }
                twitterImage {
                altText
                mediaDetails {
                    height
                    width
                }
                sourceUrl
                }
            }
            author {
                node {
                    name
                    slug
                    avatar {
                        url
                    }
                }
            }
            translated {
                seo {
                    canonical
                }
                locale{
                    locale
                }
            }
            ancestors {
                nodes {
                    slug
                    link
                    ... on WpPage {
                        id
                        title
                    }
                }
            }
        }
        allWpPost(filter: {categories: {nodes: {elemMatch: {ancestors: {nodes: {elemMatch: {databaseId: {eq: $databaseId}}}}}}}}){
          nodes {
            databaseId
            title
            content
            excerpt
            slug
            date(formatString: "DD/MM/yyyy")
            seo {
              title
              metaDesc
            }
            author {
              node {
                name
                slug
                avatar {
                  url
                }
              }
            }
            categories {
              nodes {
                databaseId
                name
                slug
                ancestors {
                  nodes {
                    databaseId
                    slug
                    uri
                    name
                  }
                }
              }
            }
            featuredImage {
              node {
                altText
                srcSet
                sourceUrl
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
    }
`;
